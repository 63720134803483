<template>
    <v-dialog
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="300px"
        min-width="300px"
        max-width="400px"
    >
        <v-card>
            <v-card-title class="success white--text justify-space-between">
                <span>{{ updateMode == false ? $t('message.addExchangeRate') : $t('message.updateExchangeRate') + ": " + exchangeRateName }}</span>
                <HelpPopUpV2 help-page-component="AddExchangeRate" :light="true" :no-padding="true"/>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                color="primary"
                                indeterminate
                                size="40"
                                width="6"
                            />
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="exchangeRateForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs6 lg5 md5 pl-5 py-1 class="align-center">{{ $t('message.currency') }}</v-flex>
                        <v-flex xs6 lg7 md7 py-1>
                            <v-autocomplete
                                    :items="allCurrencies"
                                    :rules="[...validationRules.required]"
                                    autocomplete="off"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Currency.code"
                                    item-value="Currency.id"
                                    solo
                                    v-model="Currencyrate__currency_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg5 md5 pl-5 py-1 class="align-center">{{ $t('message.rateDateFirstDay') }}</v-flex>
                        <v-flex xs6 lg7 md7 py-1>
                            <v-menu
                                    ref="dateMenu"
                                    v-model="dateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                            :value="computedDateFormatted"
                                            append-icon="event"
                                            autocomplete="new-password"
                                            clearable
                                            class="custom force-text-left"
                                            hint="DD/MM/YY format"
                                            hide-details="auto"
                                            background-color="white"
                                            persistent-hint
                                            readonly
                                            solo
                                            @click:clear="Currencyrate__dated = null"
                                            v-on="on"
                                    />
                                </template>
                                <v-date-picker
                                        first-day-of-week="1"
                                        locale-first-day-of-year="4"
                                        show-week
                                        @input="dateMenu = false"
                                        v-model="Currencyrate__dated"
                                />
                            </v-menu>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg5 md5 pl-5 py-1 class="align-center">{{ $t('message.rateAgainstRm') }}</v-flex>
                        <v-flex xs6 lg7 md7 py-1>
                            <v-text-field
                                    :rules="[...validationRules.required, ...validationRules.amount]"
                                    :value="Currencyrate__rate"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Currencyrate__rate = $event"
                            />
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addExchangeRate()"
                        v-if="updateMode == false"
                >{{ $t('message.add') }}</v-btn>
                <v-btn
                        :loading="loading.save"
                        color="info"
                        small
                        @click="saveExchangeRate()"
                        v-if="updateMode == true"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {formatDate} from "Helpers/helpers";
const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");

export default {
    name: "AddExchangeRate",
    components: { HelpPopUpV2 },
    props: ['exchangeRateId','exchangeRateName','dialog','updateMode','dialog-closed','update-done','create-done'],
    data() {
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            dateMenu: false,
            loading: {
                add: false,
                get: false,
                save: false
            },
            edit_dialog: false,
            // rules: {
            //     date: v => !!v || this.$t('message.required'),
            //     currency: v => !!v || this.$t('message.required'),
            //     rate: v => !!v || this.$t('message.required')
            // },
            validForm: true
        }
    },
    computed: {
        statePrefix() {
            return this.updateMode ? 'update' : 'current'
        },
        ...mapFieldsPrefixed('exchangerate',{
            Currencyrate__currency_id: 'Currencyrate.currency_id',
            Currencyrate__dated: 'Currencyrate.dated',
            Currencyrate__id: 'Currencyrate.id',
            Currencyrate__rate: 'Currencyrate.rate'
        },'statePrefix'),
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        ...mapGetters([
            // 'currencies',
            'validationRules'
        ]),
        computedDateFormatted(){
            return formatDate(this.Currencyrate__dated)
        },
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        ...mapActions('currency', {
            getAllCurrencies: 'getAllCurrencies'
        }),
        ...mapActions('exchangerate',{
            createExchangeRate: 'createExchangeRate',
            getExchangeRateToUpdateById: 'getExchangeRateToUpdateById',
            resetExchangeRateToUpdate: 'resetExchangeRateToUpdate',
            resetCurrentState: 'resetCurrentState',
            updateExchangeRate: 'updateExchangeRate'
        }),
        addExchangeRate () {
            if( this.$refs.exchangeRateForm.validate() ) {
                this.loading.add = true
                this.createExchangeRate()
                    .then((status) => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.exchangeRateAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            this.$toast.error(this.$t('message.errors.exchangeRateNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.exchangeRateNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.exchangeRateNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        saveExchangeRate () {
            if( this.$refs.exchangeRateForm.validate() ) {
                this.loading.save = true
                this.updateExchangeRate()
                    .then((status) => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.exchangeRateUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done')
                        } else {
                            this.$toast.error(this.$t('message.errors.exchangeRateNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.exchangeRateNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.exchangeRateNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        }
    },
    watch: {
        dialog (value) {
            if(value == true){
                if(this.updateMode == true) {
                    this.loading.get = true
                    this.getExchangeRateToUpdateById(this.exchangeRateId)
                        .then(()=>{
                            this.loading.get = false
                        })
                } else {
                    this.resetCurrentState()
                }
            } else {
                if(this.updateMode == true) {
                    this.resetExchangeRateToUpdate()
                } else {
                    this.resetCurrentState()
                }
            }
            this.edit_dialog = value
        }
    },
    mounted() {
        if(this.allCurrencies.length == 0) this.getAllCurrencies()
    }
}
</script>

<style>

</style>